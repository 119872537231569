import React from "react";
import { BrowserRouter as Router , Routes,  Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Ahz101 from "./pages/Ahz101";
import Ahz102 from "./pages/Ahz102";
import Ahz103 from "./pages/Ahz103";
import Ahz104 from "./pages/Ahz104";
import Ahz105 from "./pages/Ahz105";
import Ahz201 from "./pages/Ahz201";
import Ahz202 from "./pages/Ahz202";
import Ahz203 from "./pages/Ahz203";
import Ahz204 from "./pages/Ahz204";
import Ahz301 from "./pages/Ahz301";
import Ahz401 from "./pages/Ahz401";
import Ahz501 from "./pages/Ahz501";

function App() {
  return (
    <>
      <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/Ahz101" element={<Ahz101/>} />
            <Route path="/Ahz102" element={<Ahz102/>} />
            <Route path="/Ahz103" element={<Ahz103/>} />
            <Route path="/Ahz104" element={<Ahz104/>} />
            <Route path="/Ahz105" element={<Ahz105/>} />
            <Route path="/Ahz201" element={<Ahz201/>} />
            <Route path="/Ahz202" element={<Ahz202/>} />
            <Route path="/Ahz203" element={<Ahz203/>} />
            <Route path="/Ahz204" element={<Ahz204/>} />
            <Route path="/Ahz301" element={<Ahz301/>} />
            <Route path="/Ahz401" element={<Ahz401/>} />
            <Route path="/Ahz501" element={<Ahz501/>} />
          </Routes>
      </Router>
    </>
  );
}

export default App;
