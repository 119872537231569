import React from 'react'
import {Link} from 'react-router-dom'

function Header() {
  return (
    <>
        <header>
        <div className="container">
            <Link to="/" className="logo"><img src="images/logo.png" alt="" /></Link>
            <Link to="/" className="home-icon-link"><img src="images/home-icon.png" alt="" /></Link>
            <nav>
                <Link to="/">Home</Link>
            </nav>
        </div>
        </header>
    </>
  )
}

export default Header