import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>

    <section className="hero-section">
        <div className="container">
            <div className="hero-content">
                <h5>We are</h5>
                <h1 className="secondary-font primary">ZEAL TANNERS</h1>
                <h4>Producing sustainable leathers with guaranteed high quality workmanship.</h4>
            </div>
        </div>
    </section>
    {/*End Hero Section*/}

    <main>

<section className="section">
    <div className="container">

        <div className="section-header text-center">
            <h2 className="secondary-font headding-text-color">Our Products</h2>
            <h6 className="fw-medium">Our calf leathers are tanned sustainably and LWG-certified. With a focus on
                traceability, our hides meet high quality standards for varied applications.</h6>
        </div>

        <div className="section-content">
            <div className="products">

                <Link to='/Ahz101' className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ101.png" alt="" /></figure>
                    </div>
                    <h4>AHZ101</h4>
                    <p>Available in 5 Colours</p>
                </Link>
                <Link to="/Ahz102" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ102.png" alt="" /></figure>
                    </div>
                    <h4>AHZ102</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz201" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ201.png" alt="" /></figure>
                    </div>
                    <h4>AHZ201</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz301" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ301.png" alt="" /></figure>
                    </div>
                    <h4>AHZ301</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz203" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ203.png" alt="" /></figure>
                    </div>
                    <h4>AHZ203</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz103" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ103.png" alt="" /></figure>
                    </div>
                    <h4>AHZ103</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz204" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ204.png" alt="" /></figure>
                    </div>
                    <h4>AHZ204</h4>
                    <p>Available in 2 Colours</p>
                </Link>

                <Link to="/Ahz401" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ401.png" alt="" /></figure>
                    </div>
                    <h4>AHZ401</h4>
                    <p>Available in 3 Colours</p>
                </Link>

                <Link to="/Ahz501" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ501.png" alt="" /></figure>
                    </div>
                    <h4>AHZ501</h4>
                    <p>Available in single colour</p>
                </Link>

                <Link to="/Ahz202" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ202.png" alt="" /></figure>
                    </div>
                    <h4>AHZ202</h4>
                    <p>Available in 4 Colours</p>
                </Link>

                <Link to="/Ahz104" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ104.png" alt="" /></figure>
                    </div>
                    <h4>AHZ104</h4>
                    <p>Available in 5 Colours</p>
                </Link>

                <Link to="/Ahz105" className="product text-center">
                    <div className="product-image">
                        <figure><img src="images/AHZ105.png" alt="" /></figure>
                    </div>
                    <h4>AHZ105</h4>
                    <p>Available in 5 Colours</p>
                </Link>

            </div>
        </div>

    </div>
</section>
{/*End Section*/}

</main>
{/*End Main*/}


    </>
  )
}

export default Home