import React from 'react'

function Ahz201() {
  return (
    <>
        <section className="section">
            <div className="container">

                <section className="product-details-section">

                    <div className="product">
                        <div className="product-image">
                            <figure><img src="images/AHZ201.png" alt="" /></figure>
                        </div>
                    </div>

                    <div className="product-details">
                        <div>
                            <h6 className="secondary-font">Article</h6>
                            <h2 className="secondary-font">AHZ201 <span>(Brown)</span></h2> 
                        </div>
                        <div>
                            <p>All our calf leathers are from <span className="fw-semibold">FRENCH Origin</span> completely milk fed and <span className="fw-semibold">100%</span> geographically traceable.</p>
                            <p>Our <span className="fw-semibold">LWG-certified</span> tannery follows sustainable tanning
                                practices, ensuring the highest quality standards.</p>
                        </div>
                        <div className="product-table">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><span className="icon"><img src="images/substance.svg" alt="" /></span> Substance</p>
                                        </td>
                                        <td>mm.</td>
                                        <td className="text-center"><span className="fw-semibold">1.2 to 1.4</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><span className="icon"><img src="images/size.svg" alt="" /></span> Size</p>
                                        </td>
                                        <td>Sq.Ft.</td>
                                        <td className="text-center"><span className="fw-semibold">21 to 26</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><span className="icon"><img src="images/moq.svg" alt="" /></span> MOQ</p>
                                        </td>
                                        <td>Pcs.</td>
                                        <td className="text-center"><span className="fw-semibold">20</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="colors">
                            <div className="color-item">
                                <div className="color black-bg"></div>
                                <p>Black</p>
                            </div>
                            <div className="color-item">
                                <div className="color navy-blue-bg"></div>
                                <p>Navy Blue</p>
                            </div> 
                            <div className="color-item">
                                <div className="color tan-bg"></div>
                                <p>Tan</p>
                            </div> 
                            <div className="color-item">
                                <div className="color dark-green-bg"></div>
                                <p>Dark Green</p>
                            </div>                                                                                    
                        </div>
                        <div className="description">
                            <p className="secondary fw-semibold">Description</p>
                            <p>These elegant leathers lend to fashionable items like premium dress shoes, western boots, hand bags, attachés and wallets. <br/> <br/>
                                 These calf leathers are highly suitable for Goodyear welted shoe construction.</p>
                        </div>
                        <div className="product-display">
                            <p className="secondary fw-semibold">Product crafted from our leather</p>
                            <figure>
                                <img src="images/product-1.png" alt="" />
                            </figure>
                        </div>
                        <div className="contact">
                            <img src="images/mail-icon.svg" alt="" />
                            <a href="/" className="fw-semibold link-color">siva@zealtanners.in</a>
                        </div>
                    </div>

                </section>

            </div>
        </section>
    </>
  )
}

export default Ahz201